import { Box, styled, Typography } from "@mui/material";
import TgImg from '../assets/tg.png';
import TwitterImg from '../assets/tw.png';
import Deximg from '../assets/dx.png';
import Es from '../assets/es.png';
import links from '../links.json';

const Description = () => {
    const Item = styled(Box)(({
        height: '100%',
        width: '17.5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    }))

    const Text = styled(Typography)(({
        fontFamily: 'Arial',
        fontWeight: 700,
    }))

    return(
        <Box sx={{ height: '17.5%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', mb: '2%' }}>
            <Item>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>LP</Text>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>LCKD</Text>
            </Item>
            <Item>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>TAX</Text>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>2/2</Text>
            </Item>
            <Item>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>TWITTER</Text>
                <a href={links.twitter} target="_blank" rel="noreferrer" style={{
                    height: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}>
                    <img src={TwitterImg} alt="X" style={{height: '100%'}} />
                </a>
            </Item>
            <Item>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>TG</Text>
                <a href={links.telegram} target="_blank" rel="noreferrer" style={{
                    height: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}><img src={TgImg} alt="Telegram" style={{height: '100%'}}/></a>
            </Item>
            <Item>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>CHART</Text>
                <a href={`https://www.dextools.io/app/en/ether/pair-explorer/${links.pair}`} target="_blank" rel="noreferrer" style={{height: '50%'}}><img src={Deximg} alt="DexTools" style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}/></a>
            </Item>
            <Item>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>SCAN</Text>
                <a href={`https://etherscan.io/token/${links.contract}`} target="_blank" rel="noreferrer" style={{height: '50%'}}><img src={Es} alt="Etherscan" style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}/></a>
            </Item>
        </Box>
    );
};

export default Description;