import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts.css'
import App from './App';

import '@fontsource/anybody/300.css';
import '@fontsource/anybody/400.css';
import '@fontsource/anybody/500.css';
import '@fontsource/anybody/700.css';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/700.css';

import '@fontsource/shrikhand/400.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
