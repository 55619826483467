import { styled, Box } from "@mui/material";

const Signature = styled(Box)({
    width: '64%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5%',
    paddingTop: '2%'
});

export default Signature;