import { styled, Box } from "@mui/material";

const BarCode = styled(Box)({
    paddingTop: '5px', 
    paddingBottom: '5px',
    paddingLeft: '10px',
    width: '36%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

export default BarCode;