import { Box, Typography, styled, Stack } from "@mui/material";

const Hawaii = () => {
    const Text = styled(Typography)(({
        fontFamily: 'Nunito',
        fontSize:  '1.75rem',
        fontWeight: '500',
        color: '#354c86',
        marginLeft: '5px'
    }));

    return(
        <Box sx={{ height: '30%', width: '100%', display: 'flex', alignItems: 'flex-end'}}>
            <Box sx={{ height: '100%', width: '65%', display: 'flex', pl: '5px' }}>
                <Typography sx={{
                    fontFamily: 'Anybody', 
                    fontWeight: '700', 
                    fontSize: {md: '6rem', xs: '3.5rem'}, 
                    color: '#183a6a',
                    filter: 'drop-shadow(5px 3px 0px #000000)'
                    }}>HAWAII</Typography>
            </Box>
            <Stack spacing={0} sx={{ height: '75%', width: '35%', justifyContent: 'flex-end' }}>
                <Text sx={{ marginY: '-6px', fontSize: { md: '1.75rem', xs: '1.25rem' } }}>DRIVER</Text>
                <Text sx={{ marginY: '-6px', fontSize: { md: '1.75rem', xs: '1.25rem' } }}>LICENSE</Text>
            </Stack>
        </Box>
    );
};

export default Hawaii;