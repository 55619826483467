import { styled, Box } from "@mui/material";

const LicenseBox = styled(Box)({

    borderRadius: '30px',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 30px 90px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    transition: '0.7s ease'
});

export default LicenseBox;