import { CopyAll } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import links from '../links.json'

const Number = () => {
    const handleCopyAddress = () => {
        navigator.clipboard.writeText(links.contract);
        alert("Copied to clipboard!")
      };
    return(
        <Box sx={{ height: '17.5%', width: '100%', display: 'flex', alignItems: 'flex-end' }}>
            <Box sx={{ pl: '10px', height: '100%', width: '25%', display: 'flex', }}>
                <Typography sx={{fontFamily: 'Arial', fontWeight: '550', fontSize: { md: '1.3rem', xs: '1rem' }, mt: '5px'}}>
                    CONTRACT
                </Typography>
            </Box>
            <Box sx={{height: '100%', width: '70%', display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                <Typography sx={{fontFamily: 'Arial', fontWeight: '550', fontSize: {md: '0.9rem', xs: '0'}}}>
                    {links.contract}
                </Typography>
                <IconButton onClick={() => {handleCopyAddress()}}>
                    <CopyAll/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default Number;