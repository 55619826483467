import { Box, styled, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import links from '../links.json';

const BottomDetails = () => {
    const pair = links.pair
    const [marketCap, setMarketCap] = useState("$0")
    useEffect(() => {
        axios.get(`https://api.dexscreener.com/latest/dex/pairs/ethereum/${pair}`)
        .then( (res) => {
                const mcap = res.data.pair.fdv;
                
                if(isNaN(mcap)) {
                    setMarketCap("$0")
                }
                else if (mcap >= 1000000) {
                    setMarketCap(`$${(mcap / 1000000).toFixed(2)}M`)
                  } else if (mcap >= 1000) {
                    setMarketCap(`$${(mcap / 1000).toFixed(1)}K`)
                  } else {
                    setMarketCap(`$${(mcap ).toFixed(1)}`)
                  }
        })
        .catch(err => console.error(err));
    }, [])
    
    const Item = styled(Box)(({
        height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'
    }))

    const Text = styled(Typography)(({
        fontFamily: 'Arial',
        fontWeight: 700,
    }))

    return(
        <Box sx={{pl: '10px', height: '17.5%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', pr: '30px'}}>
            <Item >
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>LAUNCH DATE</Text>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>09/24/2023</Text>
            </Item>
            <Item >
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>NETWORK</Text>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem', ml: "15px"}}}>ETH</Text>
            </Item>
            {/* <Item>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>HODLRS</Text>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>0</Text>
            </Item> */}
            <Item >
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>MCAP</Text>
                <Text sx={{fontSize: {md: '1.2rem', xs: '1rem'}}}>{marketCap}
                    
                </Text>
            </Item>
        </Box>
    );
};

export default BottomDetails;