import { Box, Typography } from "@mui/material";

const Dates = () => {

    return(
        <Box sx={{ height: '15.5%', width: '95%', display: 'flex', alignItems: 'flex-end' }}>
            <Box sx={{ pl: '10px', height: '100%', width: '14%', display: 'flex'}}>
                <Typography sx={{fontFamily: 'Arial', fontWeight: '550', fontSize: { md: '1.4rem', xs: '1.1rem'}}}>
                    DOB
                </Typography>
            </Box>
            <Box sx={{ height: '100%', width: '32%', display: 'flex',alignItems: 'center' }}>
                <Typography sx={{ fontFamily: 'Arial', fontWeight: '700', fontSize: { md: '2rem', xs: '1.25rem' } }}>
                    06/03/1981
                </Typography>
            </Box>
            <Box sx={{ pl: '10px', height: '100%', width: '17%', display: 'flex', }}>
                <Typography sx={{ fontFamily: 'Arial', fontWeight: '550', fontSize: { md: '1.4rem', xs: '1.1rem'} }}>
                    CNTCT
                </Typography>
            </Box>
            <Box sx={{height: '100%', width: '34%', display: 'flex',alignItems: 'center'}}>
                <Typography sx={{fontFamily: 'Arial', fontWeight: '700', fontSize: { md: '1rem', xs: '0.5rem' } }}>
                    mcloviniterc@gmail.com
                </Typography>
            </Box>
        </Box>
    );
};

export default Dates;