import { styled, Stack } from "@mui/material";

const ModalBox = styled(Stack)({
    height: '200px',
    width: '200px',
    borderRadius: '10px',
    color: 'white',
    backgroundColor: '#3E3E3E',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
    border: '10px solid #FC1D68',
    outline: '10px solid #92C745',
    display: 'flex',
    flexDirection: 'column',
    position: "absolute",
    alignItems: 'center',
    justifyContent: 'space-between',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    transition: '0.7s ease'
});

export default ModalBox;