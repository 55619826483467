import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { useState } from "react";
import ModalBox from "./styled/ModalBox";
import IDCheck from './assets/idcheck.jpg';
import No from './assets/id.png';

const Modal = ({setOpen}) => {
    const [no, setNo] = useState(false);
    const Text = styled(Typography)(({
        fontFamily: 'Shrikhand'
    }))

    return(
       <ModalBox
            sx={{
                height: {md: '550px', xs: '70%'},
                width: {md: '550px', xs: '80%'},
                p: 5,
                overflow: 'hidden',

            }}
       >
        {no ? (<>
            <Text fontSize={'2rem'} fontWeight={500}>Nice try</Text>
            <Box component={'img'} src={No} sx={{height: '60%'}} />
            <Text fontSize={'20px'} fontWeight={500}>Go back home, it's your bedtime</Text>
        </>) : 
        (
            <>
            <Text fontSize={'2rem'}>Hold up</Text>
            <Box component={'img'} src={IDCheck} 
            sx={{width: '100%'}} 
            />
            <Text fontSize={'22px'} textAlign={'center'}>
                Please verify your age to enter
            </Text>
            <Stack 
                direction={{md: 'row', xs: 'column'}}
                spacing={2}
                sx={{
                    width: {md: '80%', xs: '90%'},
                    justifyContent: 'space-between',
                }}
            >
                <Button 

                    variant="contained"
                    onClick={() => setOpen()}
                    sx={{
                        width: {md: '50%', xs: '100%'},
                        fontFamily: 'Inter',
                        backgroundColor: '#E3CE37',
                        borderRadius: 2,
                        border: '5px solid #1CAFD0',
                        p: 2,
                        color: 'black',
                        transition: '0.3s ease',
                        '&:hover': {
                            backgroundColor: '#DF6D6D',
                            
                            color: 'white',
                        }
                    }}
                >
                    I am over  21
                </Button>
                <Button 
                    onClick={() => setNo(true)}
                    variant="contained"
                    sx={{
                        width: {md: '50%', xs: '100%'},
                        fontFamily: 'Inter',
                        backgroundColor: '#E3CE37',
                        borderRadius: 2,
                        p: 2,
                        border: '5px solid #DF6D6D',
                        
                        color: 'black',
                        transition: '0.3s ease',
                        '&:hover': {
                            backgroundColor: '#DF6D6D',
                            border: '5px solid #E3CE37',
                            color: 'white',
                        }
                    }}
                    >
                    I am under 21
                </Button>
            </Stack>
            </>
        )
        }
       </ModalBox>
    )
}

export default Modal;
