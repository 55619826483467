import { CssBaseline } from "@mui/material";
import Home from "./Home";
import background from './assets/bg2.png'
import { useState } from "react";
import Modal from "./Modal";

function App() {
  const audio = new Audio('./music.mp3')
  audio.loop = true;
  const [entered, setEntered] = useState(false);
  const enter = () => {
    setEntered(true);
    audio.play();
  }
  return (
    <>
    <CssBaseline/>
    <div className="video-wrapper">
      <video playsInline autoPlay muted loop>
        <source src='/video.mp4' type="video/mp4"/>
          Your browser does not support the video tag.
      </video>
    </div>
    <div className="container" >
      {entered ? (
      <Home/>
      ) : (
      <Modal setOpen={enter}/>
      )}
    </div>
    </>
  );
};

export default App;