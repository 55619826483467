import LicenseBox from "./styled/LicenseBox";
import Top from "./styled/Top";
import Middle from "./styled/Middle";
import Bottom from "./styled/Bottom";
import Photo from "./styled/Photo";
import Details from "./styled/Details";
import BarCode from "./styled/BarCode";
import Signature from "./styled/Signature";
import McLovinImg from './assets/mclovin.jpg';
import SignatureImg from './assets/signature.png';
import BarcodeImg from './assets/barcode.png';
import { Box, Typography } from "@mui/material";
import BoldText from "./styled/BoldText";
import Hawaii from './components/Hawaii';
import Number from "./components/Number";
import Dates from "./components/Dates";
import Description from "./components/Description";
import BottomDetails from "./components/BottomDetails";
import Rainbow from './styled/Rainbow'
import RainbowIMG from './assets/rainbow.png';
import McLovinText from './assets/mclovin-text.png'
import { useEffect, useState } from "react";
import './fonts.css'


const Home = () => {

    const [hover, setHover] = useState(false);

    const style = {
        boxShadow: hover ? 
        'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px' 
        : 
        'rgba(0, 0, 0, 0.4) 0px 30px 90px',
    };

    const responsive = {
        width: {
            md: '856px',
            xs: '570.6px'
        },
        height: {
            md: '505px',
            xs: '336.6px'
        },
        transform: {
            md: 'none',
            xs: 'rotate(90deg)'
        },
        left: { xs: -100, md: 0 },
        top: { xs: -100, md: 0},
    };

    return(
        <>
        {/* <audio autoPlay loop>
            <source src='/music.mp3' type="audio/ogg"/>
        </audio> */}
        <Box component={'img'} src={McLovinText} sx={{
            position: 'absolute',
            width: {md: '400px', xs: '200px'},
            top: {md: '10%', xs: '5%'},
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 5
        }}/>
        <Rainbow sx={[style, responsive]} className="fade-in" component={'img'} src={RainbowIMG}/>
        <LicenseBox sx={[style, responsive]} onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}>
            <Top className="fade-in">
                <Photo component={'img'} src={McLovinImg}/>
                <Details >
                    <Hawaii />
                    <Number/>
                    <Dates/>
                    <Description/>
                    <BottomDetails/>
                </Details>
            </Top>
            <Middle className="fade-in">
                <BarCode>
                    <Box component={'img'} alt="barcode" src={BarcodeImg} sx={{ mx: 'auto', height: '45%', width: '100%' }}/>
                    <BoldText>McLOVIN</BoldText>
                    <BoldText>892 MOMONA ST</BoldText>
                </BarCode>
                <Signature>
                    <img alt='signature' src={SignatureImg} height='60%' width="40%"/>
                    <Typography fontFamily={"Arial"} sx={{
                        wordWrap: 'break-word',
                        opacity: {md: 0, xs: 1},
                        fontWeight: '600',
                        fontSize: '15px'
                    }}>0x0c802b2f35d9ddB09d9F7829B3C7900796031BD3</Typography>
                </Signature>
            </Middle>
            <Bottom className="fade-in">
                <BoldText>HONOLULU, HI 96820</BoldText>
            </Bottom>
        </LicenseBox>
        </>
    )
}

export default Home;